import { Component, OnInit } from '@angular/core';
import {DEEP_URL} from "../../routing-constants";
import {Router} from "@angular/router";

@Component({
  selector: 'my-know-how',
  templateUrl: './know-how.component.html',
  styleUrls: ['./know-how.component.css', '../home.component.css']
})
export class KnowHowComponent implements OnInit {
  public insightUrl = DEEP_URL;

  constructor(public router : Router) { }

  ngOnInit() {
  }

}
