import { Component, OnInit } from '@angular/core';
import {DEEP_URL} from "../../routing-constants";

@Component({
  selector: 'my-trump-and-stake',
  templateUrl: './trump-and-stake.component.html',
  styleUrls: ['./trump-and-stake.component.css', '../home.component.css']
})
export class TrumpAndStakeComponent implements OnInit {
  public insightUrl = DEEP_URL;

  constructor() { }

  ngOnInit() {
  }

}
