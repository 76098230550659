import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UiStateStore} from "src/app/state/ui-state-store";
import {ViewportScroller} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'my-applications-light',
  templateUrl: './applications-light.component.html',
  styleUrls: ['./applications-light.component.css',
    '../home/applications/applications-brief.component.css'],
})
export class ApplicationsLightComponent implements AfterViewInit {

  @ViewChild('fast-reader') eFastReader: ElementRef;

  constructor(private viewPortScroller : ViewportScroller,
      private route : ActivatedRoute,
      public uiStore : UiStateStore) {
  }

  ngAfterViewInit() {
    this.route.fragment.subscribe(fragment => {
      this.viewPortScroller.scrollToAnchor(fragment);
    });
  }
}
