import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';

@Component({
  selector: 'app-assets',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) public locale: string) {
  }

  ngOnInit() {
  }

  isLocaleFr() : boolean {
    return this.locale === 'fr';
  }
}
