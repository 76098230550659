import {EnjeuxComponent} from "./enjeux/enjeux.component";
import {CarouselModule} from "ngx-bootstrap/carousel";
import {MethodologyAccordeonComponent} from "./methodology/methodology.accordeon.component";
import {ContactComponent} from "./contact/contact.component";
import {NgModule} from "@angular/core";
import {HomeComponent} from "./home/home.component";
import {MethodologyComponent} from "./methodology/methodology.component";
import {AtoutsComponent} from "./atouts/atouts.component";
import {ApplicationsComponent} from "./applications/applications.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {TrumpAndStakeComponent} from "./home/trump-and-stake/trump-and-stake.component";
import {KnowHowComponent} from "./home/know-how/know-how.component";
import {MethodologyApportsComponent} from "./home/methodology-apports/methodology-apports.component";
import {ApplicationsBriefComponent} from "./home/applications/applications-brief.component";
import {HomeHeaderComponent} from "./homeheader/home-header.component";
import {SharedPanelsModule} from "./shared-panels.module";
import {InteractiveDemoButtonComponent} from "./home/shared/interactive-demo-button.component";
import {ApplicationsLightComponent} from "./applications-light/applications-light.component";
import {ProjectComponent} from "./project/project.component";
import {InfoselectComponent} from "./infoselect/infoselect.component";
import {DocdiscoveryComponent} from "./docdiscovery/docdiscovery.component";
import {InforevealComponent} from "./inforeveal/inforeveal.component";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,

    SharedPanelsModule,

    NgbModule.forRoot(),
    CarouselModule.forRoot(),
  ],
  declarations: [
    HomeComponent,
    TrumpAndStakeComponent,

    MethodologyComponent,
    MethodologyAccordeonComponent,

    ProjectComponent,
    AtoutsComponent,
    ContactComponent,
    EnjeuxComponent,
    InfoselectComponent,
    InforevealComponent,
    DocdiscoveryComponent,

    KnowHowComponent,
    MethodologyApportsComponent,
    ApplicationsBriefComponent,
    ApplicationsComponent,
    ApplicationsLightComponent,

    InteractiveDemoButtonComponent,
  ]
})
export class SiteModule { }
