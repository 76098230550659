import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {EnjeuxComponent} from "./enjeux/enjeux.component";
import {ContactComponent} from "./contact/contact.component";
import {AtoutsComponent} from "./atouts/atouts.component";
import {HomeComponent} from "./home/home.component";
import {MethodologyComponent} from "./methodology/methodology.component";
import {ApplicationsLightComponent} from "./applications-light/applications-light.component";
import {ApplicationsComponent} from "./applications/applications.component";
import {ProjectComponent} from "./project/project.component";
import {InfoselectComponent} from "./infoselect/infoselect.component";
import {DocdiscoveryComponent} from "./docdiscovery/docdiscovery.component";
import {InforevealComponent} from "./inforeveal/inforeveal.component";

const HOME = 'home';
const APPLICATIONS = 'applications';


const appRoutes: Routes = [
  { path: 'contact', component: ContactComponent },

  { path: HOME, component: HomeComponent, runGuardsAndResolvers: 'always' },
  { path: APPLICATIONS, component: ApplicationsLightComponent },
  { path: 'applications-insight', component: ApplicationsComponent },
  { path: 'methodology', component: MethodologyComponent, runGuardsAndResolvers: 'always' },
  { path: 'project', component: ProjectComponent },
  { path: 'assets', component: AtoutsComponent },
  { path: 'challenges', component: EnjeuxComponent },
  { path: 'infoselect', component: InfoselectComponent },
  { path: 'inforeveal', component: InforevealComponent },
  { path: 'content-capture', component: DocdiscoveryComponent },
  { path: '', redirectTo:  HOME, pathMatch: 'full' },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        enableTracing: false,
        onSameUrlNavigation: 'reload'} // <-- debugging purposes only
    )],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
