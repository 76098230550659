import {BrowserModule} from "@angular/platform-browser";
import {FooterComponent} from "./footer/footer.component";
import {HeaderComponent} from "./header/header.component";
import {NavBarComponent} from "./navbar/navbar.component";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {StateStoreModule} from "./state/state-store.module";
import {HomeHeaderComponent} from "./homeheader/home-header.component";

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,

    StateStoreModule,
  ],
  declarations: [
    NavBarComponent,
    HeaderComponent,
    FooterComponent,
    HomeHeaderComponent,
  ],
  exports: [
    NavBarComponent,
    HeaderComponent,
    FooterComponent,
    HomeHeaderComponent,
  ]
})
export class SharedPanelsModule { }
