Array.prototype.hashCode = function () {
    let hash = 0;
    for (let i = 0; i < this.length; i++) {
        let e = this[i];
        if (!isNaN(e)) {
            hash = 31 * hash + (hash + e);
        } else if (typeof e === 'string' || e instanceof String) {
            hash += 31 * hash + e.hashCode();
        }
    }
    return hash;
};

Array.prototype.isEmpty = function () {
    return this.length === 0;
};

Array.prototype.isNotEmpty = function () {
    return this.length !== 0;
};

Array.prototype.contains = function (element) {
    return this.indexOf(element) !== -1;
};

Array.prototype.containsAnyOf = function (elements) {
    for (let element of elements) {
        if(this.indexOf(element) !== -1)
            return true;
    }
    return false;
};

Array.prototype.doesNotContain = function (element) {
    return !this.contains(element);
};

Array.prototype.toggleElement = function (e : any) {
    let index = this.indexOf(e);
    if (index !== -1) {
        this.splice(index, 1);
    } else {
        this.splice(index, 0, e);
    }
};

Array.prototype.pushAll = function (array) {
    for (var i = 0; i < array.length; i++) {
        var e = array[i];
        this.push(e);
    }
};

Array.prototype.pushAllIfNew = function (array) {
    for (var i = 0; i < array.length; i++) {
        var e = array[i];
        if (this.doesNotContain(e)) this.push(e);
    }
};

Array.prototype.union = function (array) {
    this.pushAll(array.filter(e => this.doesNotContain(e)));
};

Array.prototype.removeFirst = function (e) {
    if (this.contains(e)) {
        let index = this.indexOf(e);
        this.splice(index, 1);
    }
};

Array.prototype.parseElementsToIntegers = function () {
    let integers : number[] = [];
    for (var i = 0; i < this.length; i++) {
        var e = this[i];
        if (!isNaN(e)) {
            integers.push(parseInt(e));
        }
    }
    return integers;
};

Array.prototype.move = function (old_index, new_index) {
    if (new_index >= this.length) {
        var k = new_index - this.length;
        while ((k--) + 1) {
            this.push(undefined);
        }
    }
    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
    return this; // for testing purposes
};