import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {UiStateStore} from "../state/ui-state-store";
import {Router} from "@angular/router";

@Component({
  selector: 'my-navbar',
  templateUrl: './navbar.html',
  styleUrls: ['./navbar.component.css']
})
export class NavBarComponent  implements OnInit {

  languages = [
    { code: 'fr', label: 'Français'},
    { code: 'en', label: 'English'}
  ];

  constructor(@Inject(LOCALE_ID) public locale: string,
              public uiStore : UiStateStore,
              public router : Router) { }

  ngOnInit() {
  }

  isLocaleFr() : boolean {
    return this.locale === 'fr';
  }
}
