import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {WindowRef} from "../component/window.component";
import {NavigationEnd, Router} from "@angular/router";
import {DEEP_URL} from "../routing-constants";

@Component({
  selector: 'app-methodology',
  templateUrl: './methodology.component.html',
  styleUrls: ['./methodology.component.css']
})
export class MethodologyComponent implements OnInit, OnDestroy {

  public insightUrl = DEEP_URL;

  public selectedTab:number;
  public boxTabs:boolean[];
  private sticky:number;
  public isMethodologyDetailVisible:boolean;
  navigationSubscription;

  constructor(
    private router: Router,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DOCUMENT) private document: Document,
    private window:WindowRef) {

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  init() {
    this.selectedTab = 0;
    this.boxTabs = [true, false, false,false];
    this.isMethodologyDetailVisible=false;
  }

  changeShowStatus(event, boxNumber:number){
    event.preventDefault();
    for (let i in this.boxTabs) {
      this.boxTabs[i]=false;
      // console.log(i+"="+this.boxTabs[i]); // "0", "1", "2",
    }
    this.boxTabs[boxNumber]=true;
    this.selectedTab=boxNumber;
    this.isMethodologyDetailVisible=true;
    window.scrollTo(0, 0)
  }

  // @HostListener("window:scroll", [])
  // onWindowScroll() {
  //
  //   let element = document.getElementById("tabTitles");
  //   let elementContent = document.getElementById("tabContent");
  //   // let sticky = element.offsetTop;
  //   // let sticky=this.getOffsetTop(element);
  //   console.log("offsetTop="+this.sticky);
  //   console.log("pageOffset="+window.pageYOffset);
  //
  //     if (window.pageYOffset >= this.sticky) {
  //       element.classList.add("sticky");
  //       elementContent.classList.add("tabContent");
  //     } else {
  //       element.classList.remove("sticky");
  //       elementContent.classList.remove("tabContent");
  //     }
  //
  //   // let number = this.window.nativeWindow.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
  //   // if (number > 100) {
  //   //   this.navIsFixed = true;
  //   // } else if (this.navIsFixed && number < 10) {
  //   //   this.navIsFixed = false;
  //   // }
  // }

  // getOffsetTop(elem) : number {
  //   // Set our distance placeholder
  //   var distance = 0;
  //
  //   // Loop up the DOM
  //   if (elem.offsetParent) {
  //     do {
  //       distance += elem.offsetTop;
  //       elem = elem.offsetParent;
  //     } while (elem);
  //   }
  //
  //   // Return our distance
  //   return distance < 0 ? 0 : distance;
  // }

  ngOnInit() {
    // let element = document.getElementById("tabTitles");
    // this.sticky = this.getOffsetTop(element)-element.offsetHeight;
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  goToTop(event) {
    event.preventDefault();
    window.scrollTo(0, 0)
  }
}
