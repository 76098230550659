import {Component, Input, OnInit} from '@angular/core';
import {DEEP_URL, LIGHT_URL} from "../../routing-constants";
import {UiStateStore} from "../../state/ui-state-store";

@Component({
  selector: 'my-applications-brief',
  templateUrl: './applications-brief.component.html',
  styleUrls: ['./applications-brief.component.css', '../home.component.css']
})
export class ApplicationsBriefComponent implements OnInit {

  @Input()
  shouldHideDetails = true;

  constructor(public uiStore : UiStateStore) { }

  ngOnInit() {
  }

}
