import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs/index";
import {StateStoreModule} from "./state-store.module";

@Injectable({providedIn: StateStoreModule})
export class UiStateStore {

  private _showSiteLight : BehaviorSubject<boolean>;
  private _currentUrl: BehaviorSubject<string>;

  constructor() {
    this._showSiteLight = new BehaviorSubject<boolean>(true);
    this._currentUrl = new BehaviorSubject<string>('');
  }

  get isSiteLight() : Observable<boolean> {
    return this._showSiteLight.asObservable();
  }

  get currentUrl() : Observable<string> {
    return this._currentUrl.asObservable();
  }

  setSiteVisuModeLight() : void {
    this._showSiteLight.next(true);
  }

  setCurrentUrl(url : string) : void {
    this._currentUrl.next(url);
  }

  setSiteVisuModeInsight() : void {
    this._showSiteLight.next(false);
  }

  toggleSiteVisuMode() : void {
    let mode = this._showSiteLight.getValue();
    this._showSiteLight.next(!mode);
  }
}
