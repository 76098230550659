import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CarouselConfig} from "ngx-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {UiStateStore} from "src/app/state/ui-state-store";

@Component({
  selector: 'app-solutions',
  templateUrl: './docdiscovery.component.html',
  styleUrls: ['./docdiscovery.component.css', '../home/applications/applications-brief.component.css', '../home/know-how/know-how.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: false, noPause: false, showIndicators: false } }
  ]
})
export class DocdiscoveryComponent implements OnInit {

  public activeIndex1:number;
  public activeIndex2:number;
  public activeIndex3:number;
  private anchorClassName: string;

  @ViewChild('appli1') appli1: ElementRef;
  @ViewChild('appli2') appli2: ElementRef;
  @ViewChild('appli3') appli3: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elRef:ElementRef,
    private cd: ChangeDetectorRef,
    public uiStore : UiStateStore) {

    this.activeIndex1=0;
    this.activeIndex2=0;
    this.activeIndex3=0;
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      console.log(`Query params change.`);
      console.log(`Param 1: ${params['activeIndex1']}`);
      console.log(`Param 2: ${params['activeIndex2']}`);
      console.log(`Param 3: ${params['activeIndex3']}`);

      this.activeIndex1 = params['activeIndex1'] || 0;
      this.activeIndex2 = params['activeIndex2'] || 0;
      this.activeIndex3 = params['activeIndex3'] || 0;
      this.anchorClassName = params['anchorClassName'];

      console.log(`Current values:`);
      console.log(`anchorClass ${this.anchorClassName}`);
      console.log(`activeIndex1 ${this.activeIndex1}`);
      console.log(`activeIndex2 ${this.activeIndex2}`);
      console.log(`activeIndex3 ${this.activeIndex3}`);

      this.scrollToSelectedApplication();
    });
    setTimeout(() => this.cd.reattach());
    this.cd.detectChanges();
  }

  private scrollToSelectedApplication() {
    if (this.anchorClassName !== undefined) {
      if (this.anchorClassName === ".appli1") {
        // this.gotoSlideForAppli1(element,  this.activeIndex1);
        if (this.appli1 !== undefined) {
          this.gotoSlideForAppli1(this.appli1.nativeElement, this.activeIndex1);
          console.log("enter1");
        }
      }
      else if (this.anchorClassName === ".appli2") {
        if (this.appli2 !== undefined) {
          // var element = this.elRef.nativeElement.querySelector('.appli2')
          // this.gotoSlideForAppli2(element,  this.activeIndex2);
            this.gotoSlideForAppli2(this.appli2.nativeElement, this.activeIndex2);
            console.log("enter2");
          }
        }
      else if (this.anchorClassName === ".appli3") {
        // var element = this.elRef.nativeElement.querySelector('.appli3')
        // this.gotoSlideForAppli3(element,  this.activeIndex3);
        if (this.appli3 !== undefined) {
          this.gotoSlideForAppli3(this.appli3.nativeElement, this.activeIndex3);
          console.log("enter3");
        }
      }
    }
  }

  ngOnDestroy() {
  }

  gotoSlideForAppli1(el, value: number): void {
    el.scrollIntoView();
    this.activeIndex1=value;
    console.log("select="+value);
  }

  gotoSlideForAppli2(el, value: number): void {
    el.scrollIntoView();
    this.activeIndex2=value;
  }

  gotoSlideForAppli3(el, value: number): void {
    el.scrollIntoView();
    this.activeIndex3=value;
  }
}
