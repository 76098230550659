import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {DEEP_URL} from "../routing-constants";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  public insightUrl = DEEP_URL;
  constructor(@Inject(LOCALE_ID) public locale: string) {
  }

  ngOnInit() {
  }

  // goToPage(pageName:string,el, slideNumber: number) {
  //   this.router.navigate([pageName], { queryParams: { element:el, slideNumber: slideNumber } });
  // }

  isLocaleFr() : boolean {
    return this.locale === 'fr';
  }
}
