import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {DeviceDetectorService} from 'ngx-device-detector';
import {UiStateStore} from "./state/ui-state-store";
import {DEEP_URL, LIGHT_URL} from "./routing-constants";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']

})
export class AppComponent implements OnInit {

  public languages = [
    {code: 'en', label: 'English'},
    {code: 'fr', label: 'Français'}
  ];

  public isDesktop: boolean;

  constructor(
    private router: Router,
    private uiStore : UiStateStore,
    @Inject(LOCALE_ID) protected localeId: string, private deviceService: DeviceDetectorService) {

    this.deviceDetectorFunction();
  }

  ngOnInit() {
    this.scrollToTopOnNavigationEndRouterEvent();
    this.setSiteVisuMode();
  }

  private setSiteVisuMode() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd ) {
        let currentUrl = event.url;
        this.uiStore.setCurrentUrl(currentUrl);
        if (currentUrl.contains(LIGHT_URL)) {
          this.uiStore.setSiteVisuModeLight();
        } else {
          this.uiStore.setSiteVisuModeInsight();
        }
      }
    });
  }

  private scrollToTopOnNavigationEndRouterEvent() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        this.router.navigated = false;
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  deviceDetectorFunction() {
    this.isDesktop = true;
    // if(this.deviceService.isMobile()) {
    //   this.isDesktop = false;
    // }
    // console.log("is Desktop device enabled =" + this.isDesktop);
  }
}
