/**
 * Created by helinko on 2016.12.06..
 */
String.prototype.hashCode = function () {
    var hash = 0, i: number, chr: number, len: number;
    if (this.length === 0) return hash;
    for (i = 0, len = this.length; i < len; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

String.prototype.contains = function (string) {
    if (string.length === 0) return false;
    return this.indexOf(string) !== -1;
};

String.prototype.isEmpty = function () {
    let isEmpty = this.length === 0;
    return isEmpty;
};

String.prototype.isNotEmpty = function () {
    return !this.isEmpty();
};

String.prototype.replaceAll = function (str1, str2, ignore): string {
    return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"),
        (ignore ? "gi" : "g")), (typeof(str2) === "string") ? str2.replace(/\$/g, "$$$$") : str2);
};

String.prototype.firstCharToLowerCase = function () {
    return this.substr(0, 1).toLocaleLowerCase() + this.substr(1, this.length);
};

String.prototype.camelCaseToLowerCaseHyphen = function () {
    return this.replace(/\W+/g, '-')
        .replace(/([a-z\d])([A-Z])/g, '$1-$2')
        .toLocaleLowerCase();
};