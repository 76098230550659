import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {APP_BASE_HREF, registerLocaleData} from "@angular/common";
import {WindowRef} from "./component/window.component";
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {AppRoutingModule} from "./app-routing.module";
import {SharedPanelsModule} from "./shared-panels.module";
import {SiteModule} from "./site.module";

import './utils/array-extentions';
import './utils/string-extentions';
import {WINDOW_PROVIDERS} from "./utils/window-providers";
import {ContactComponent} from "./contact/contact.component";

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

@NgModule({
  imports: [
    BrowserModule,
    DeviceDetectorModule.forRoot(),

    SharedPanelsModule,

    SiteModule,
    //must be imported at the end when wildcard routes are present
    AppRoutingModule,

  ],
  declarations: [
    AppComponent
  ],
  providers: [
    WindowRef,
    WINDOW_PROVIDERS,
    { provide: APP_BASE_HREF, useValue: '/'},
    { provide: LOCALE_ID, useValue: 'fr' }
    ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
