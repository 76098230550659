import { Component, OnInit } from '@angular/core';
import {DEEP_URL} from "../../routing-constants";

@Component({
  selector: 'my-methodology-apports',
  templateUrl: './methodology-apports.component.html',
  styleUrls: ['./methodology-apports.component.css', '../home.component.css']
})
export class MethodologyApportsComponent implements OnInit {
  public insightUrl = DEEP_URL;

  constructor() { }

  ngOnInit() {
  }

}
