import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-assets',
  templateUrl: './atouts.component.html',
  styleUrls: ['./atouts.component.css']
})
export class AtoutsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    // $(document).ready(function(){ //Photos Gallery
    //   $(".fancybox").fancybox({
    //     openEffect: "elastic",
    //     closeEffect: "none"
    //   });
    // });
  }

  ngOnDestroy() {
    // $(".fancybox").unbind('click.fb');
    // or maybe jQuery(".fancybox").off() to remove all bindings
  }

}
