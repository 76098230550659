import {Component, Inject, OnInit} from '@angular/core';
import {WINDOW} from "../../utils/window-providers";
import {PORT_DEMO, URL_DEMO} from "../../live-demo.constants";

@Component({
  selector: 'my-interactive-demo-button',
  templateUrl: 'interactive-demo-button.component.html'
})

export class InteractiveDemoButtonComponent implements OnInit {
  public hostName: string = "";

  constructor(@Inject(WINDOW) private window: Window) {
  }

  getHostname() : string {
    return window.location.hostname;
  }

  ngOnInit() {
    this.hostName = this.getHostname();
    // console.log(this.hostName);
  }

  getDemoURL() : string {
    return `${this.getHostname()}:${PORT_DEMO}${URL_DEMO}`
  }
}
